import { WebGLRenderer } from 'three';
import { canvasWidth, canvasHeight, pixelRatio } from "./config";

function createRenderer() {
    const renderer = new WebGLRenderer({ antialias: true, alpha: true });

    renderer.setPixelRatio(pixelRatio);
    renderer.setSize(canvasWidth(), canvasHeight());
    renderer.setClearColor(0x000000, 0);

    return renderer;
}

export { createRenderer };
