const container = document.querySelector('#scene-container');


const aspect = 1.2;
const cameraZ = 300;
// const canvasWidth = () => window.innerWidth / 1.8;
// const canvasHeight = () => canvasWidth() / aspect;

const canvasWidth = () => container.offsetWidth;
const canvasHeight = () => canvasWidth() / aspect;

// const canvasWidth = () => window.innerWidth;
// const canvasHeight = () => window.innerHeight
const pixelRatio = () => Math.min(Math.max(window.devicePixelRatio, 1), 2)
// const aspect = canvasWidth() / canvasHeight();

export { aspect, cameraZ, canvasWidth, canvasHeight, pixelRatio };